'use client';
import React, { useEffect, useState } from 'react';
import { useSearchParams, useRouter } from 'next/navigation';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import { colors } from '@/lib/theme/palette';
import ButtonComponent from '@/shared/components/ButtonComponent';
import KeywordsFilter from '@/shared/components/KeywordsFilter';
import QueryFilter from '@/shared/components/QueryFilter';
// import SwitchFilter from '@/shared/components/SwitchFilter';
import ChipFilter from '@/shared/components/ChipFilter';
// import CategoriesDataApi from '@/shared/apis/categories/categories.api';
import ResourceTypeApi from '@/shared/apis/resourceType/resourceType.api';
// import { filterCategories } from '@/lib/utils';
import {
  FilterValues,
  AvailableFilters,
} from '@/shared/components/MobileFilter/types.d';
import Icon from '../Icon';
import { TCategory } from '@/shared/apis/categories/types.categories';
import { TResourceType } from '@/shared/apis/resourceType/types.d';
import TechonologiesGroupFilter from '../TechonologiesGroupFilter';
import { MappedCategoryType } from '@/app/technologies/categories.types';

const companyTypes = [
  {
    name: 'Software',
    value: 'software',
  },
  {
    name: 'Services',
    value: 'services',
  },
];

type DefaultFilterValues = {
  [key: string]: string[];
};

type FilterDialogType = {
  searchType?: string;
  catalogMode?: string | undefined;
  categories: MappedCategoryType[];
  generalSubCategories: TCategory[];
  open: boolean;
  handleClose: () => void;
  isMobile?: boolean;
  defaultFilterValues?: DefaultFilterValues | undefined;
  applyCallback?: ((values: DefaultFilterValues) => void) | undefined;
};

export default function FilterDialog({
  open,
  handleClose,
  searchType,
  catalogMode,
  applyCallback,
  isMobile,
  defaultFilterValues,
  categories,
  generalSubCategories,
}: FilterDialogType) {
  const searchParams = useSearchParams();
  const { push } = useRouter();
  const params = new URLSearchParams(searchParams);
  const companyType = params.get('companyType') || '';
  const categoriesType = params.get('categories');
  const resourceType = params.get('resourceType');
  const keywords = params.get('keywords');
  const query = params.get('query');
  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>({
    companyType: companyTypes,
    categories: [],
    resourceType: [],
  });
  const [filteredResourceTypes, setFilteredResourceTypes] = useState<
    TResourceType[]
  >(
    availableFilters.resourceType.map(resourceType => ({
      ...resourceType,
      resources_count: null,
    })),
  );

  const [filterValues, setFilterValues] = useState<FilterValues>({
    companyType: [],
    categories: [],
    resourceType: [],
    keywords: [],
    query: '',
  });

  const [searchValue, setSearchValue] = React.useState<string>('');

  const fetchResourceTypes = async () => {
    const { data: resourceTypes } = await ResourceTypeApi.FetchResourceTypes({
      pageCount: 10000,
    });
    return resourceTypes;
  };

  const applyFilters = () => {
    if (applyCallback) {
      applyCallback({
        resourceType: filterValues.resourceType,
        keywords: filterValues.keywords,
      });
    } else {
      let prop: keyof FilterValues;
      for (prop in filterValues) {
        if (prop === 'query') {
          if (filterValues[prop]) {
            params.set('query', filterValues[prop]);
          } else {
            params.delete(prop);
          }
        } else {
          if (filterValues[prop].length) {
            params.set(prop, filterValues[prop].join(','));
          } else {
            params.delete(prop);
          }
        }
      }
      params.set('page', '1');
      if (catalogMode) {
        push(`${catalogMode}?${decodeURIComponent(params.toString())}`, {
          scroll: false,
        });
      }
    }
    handleClose();
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const onClearKeywords = () => {
    setFilterValues({
      ...filterValues,
      keywords: [],
    });
  };

  const onRouteChange = (route: string | undefined) => {
    if (route) {
      setFilterValues({
        ...filterValues,
        categories: route.split(','),
      });
    } else {
      setFilterValues({
        ...filterValues,
        categories: [],
      });
    }
  };

  useEffect(() => {
    const fetchFilters = async () => {
      const [resourceType] = await Promise.all([fetchResourceTypes()]);
      setAvailableFilters({
        ...availableFilters,
        categories: generalSubCategories,
        resourceType: resourceType,
      });

      setFilteredResourceTypes(resourceType);

      setFilteredResourceTypes(resourceType);
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    if (defaultFilterValues) {
      setFilterValues({
        ...filterValues,
        resourceType: defaultFilterValues.resourceType,
        keywords: defaultFilterValues.keywords,
      });
    } else {
      setFilterValues({
        companyType: companyType ? companyType.split(',') : [],
        categories: categoriesType ? categoriesType.split(',') : [],
        resourceType: resourceType ? resourceType.split(',') : [],
        keywords: keywords ? keywords.split(',') : [],
        query: query || '',
      });
    }
  }, []);

  useEffect(() => {
    setFilteredResourceTypes(
      availableFilters.resourceType.filter(c =>
        c.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    );
  }, [searchValue]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="xs"
      fullWidth
      fullScreen
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
        }}
      >
        <DialogTitle
          display="flex"
          alignItems="center"
          fontWeight={700}
          mb={'7px'}
        >
          Filter results
          <Icon
            icon="close"
            width={24}
            height={24}
            sx={{
              marginLeft: 'auto',
            }}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          {searchType === 'companies' ? (
            <>
              <QueryFilter
                isMobile={isMobile}
                searchType="companies"
                filterValues={filterValues}
                setFilterValues={setFilterValues}
              />
              <TechonologiesGroupFilter
                categories={categories}
                generalSubCategories={generalSubCategories}
                filterParams={categoriesType}
                selectedReactiveFilters={filterValues.categories}
                onRouteChange={onRouteChange}
              />

              {/* <ChipFilter
                hasSearchField
                title="Company categories"
                filterName="categories"
                search={searchValue}
                filters={filteredCategories.map(c => ({
                  name: c.name,
                  value: c.value,
                }))}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                onSearch={handleSearch}
              /> */}
            </>
          ) : (
            ''
          )}
          {searchType === 'resources' ? (
            <>
              {catalogMode ? (
                <QueryFilter
                  isMobile={isMobile}
                  searchType="resources"
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                />
              ) : (
                ''
              )}
              <KeywordsFilter
                title="Search by keyword"
                placeholder="Enter keyword"
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                onClearKeywords={onClearKeywords}
                hideResetFilter={filterValues.keywords.length === 0}
                catalogMode="library"
              />
              <ChipFilter
                title="Resource type"
                filterName="resourceType"
                filters={filteredResourceTypes.map(c => ({
                  name: c.name,
                  value: c.value,
                }))}
                search={searchValue}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                onSearch={handleSearch}
              />
            </>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: '16px 24px 24px',
            position: 'sticky',
            marginTop: 'auto',
            bottom: 0,
            backgroundColor: colors.white,
            borderTop: `1px solid ${colors.black300}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              gap: 1,
            }}
          >
            <ButtonComponent
              onClick={applyFilters}
              sx={{
                border: 'none',
                color: colors.white,
                backgroundColor: colors.blue,
                height: '42px',
                fontSize: '14px',
              }}
            >
              Apply Filters
            </ButtonComponent>
            <ButtonComponent
              variant="outlined-primary"
              onClick={() =>
                setFilterValues({
                  companyType: [],
                  categories: [],
                  resourceType: [],
                  keywords: [],
                  query: '',
                })
              }
              sx={{
                height: '42px',
                fontSize: '14px',
              }}
            >
              Reset Filters
            </ButtonComponent>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
