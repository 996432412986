'use client';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type DesktopComponentProps = {
  children: React.ReactNode;
};

export default function DesktopRenderComponent({
  children,
}: DesktopComponentProps) {
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return isDesktopScreen ? children : '';
}
