'use client';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useRouter } from 'next/navigation';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { colors } from '@/lib/theme/palette';
import KeywordsDataApi from '@/shared/apis/keywords/keywords.api';
import OutlinedInput from '@mui/material/OutlinedInput';
import Icon from '@/shared/components/Icon';
import { TKeyword } from '@/shared/apis/keywords/types.keywords.d';
import { getHighlightedText, removeItemAll } from '@/lib/utils';
import KeywordValuesList from '@/shared/components/KeywordValuesList';
import { SxProps } from '@mui/system';
import { FilterValues } from '@/shared/components/MobileFilter/types.d';

type KeywordsFilterProps = {
  title?: string;
  placeholder: string;
  catalogMode?: string;
  callback?: (filter: string, value: string) => void;
  sx?: SxProps;
  filterValues?: FilterValues;
  setFilterValues?: (filterValues: FilterValues) => void;
  onClearKeywords?: () => void;
  hideResetFilter?: boolean;
};

export default function KeywordsFilter({
  title,
  placeholder,
  catalogMode,
  callback,
  filterValues,
  setFilterValues,
  onClearKeywords,
  hideResetFilter,
}: KeywordsFilterProps) {
  const { push } = useRouter();
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const keywordsParam = params.get('keywords');
  const selectedKeywords = keywordsParam ? keywordsParam.split(',') : [];
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [keywordsResults, setKeywordsResults] = useState<TKeyword[]>([]);
  const searchStarted = searchQuery && searchQuery.length >= 3;

  const fetchData = useCallback(
    async (controller?: AbortSignal) => {
      try {
        const { data: keywordsList } = await KeywordsDataApi.GetKeywordsList(
          {
            query: searchQuery,
          },
          controller,
        );
        setKeywordsResults(keywordsList);
      } catch (error) {
        console.error(error);
      }
    },
    [searchQuery],
  );

  useEffect(() => {
    const controller = new AbortController();

    if (searchStarted) {
      fetchData(controller.signal);
    } else {
      setKeywordsResults([]);
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleFocus = () => {
    setShowMenu(true);
  };

  const handleSearchFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const toggleKeywordFilter = (name: string) => {
    if (callback) {
      callback('keywords', name);
    } else if (filterValues && setFilterValues) {
      // filterValues && setFilterValues means it is mobile
      let newValues: string[] = [];
      const keywordsValues = filterValues.keywords;
      if (keywordsValues.includes(name)) {
        newValues = removeItemAll([...keywordsValues], name);
      } else {
        newValues = [...keywordsValues];
        newValues.push(name);
      }
      setFilterValues({
        ...filterValues,
        keywords: newValues,
      });
    } else {
      const keywords = params.get('keywords');
      if (keywords) {
        const keywordsValues = keywords.split(',');
        if (keywordsValues.includes(name)) {
          const newValues = removeItemAll([...keywordsValues], name);
          if (!newValues.length) {
            params.delete('keywords');
          } else {
            params.set('keywords', newValues.join(','));
          }
        } else {
          const newValues = [...keywordsValues];
          newValues.push(name);
          params.set('keywords', newValues.join(','));
        }
      } else {
        params.set('keywords', name);
      }
      params.set('page', '1');
      if (catalogMode) {
        push(`${catalogMode}?${decodeURIComponent(params.toString())}`, {
          scroll: false,
        });
      }
    }
  };

  const clearAllKeywords: () => void = () => {
    if (onClearKeywords) {
      onClearKeywords();

      return;
    }

    params.delete('keywords');
    params.set('page', '1');
    push(`${catalogMode}?${decodeURIComponent(params.toString())}`, {
      scroll: false,
    });
  };

  const onKeywordClick = (e: React.MouseEvent<HTMLElement>) => {
    const { name } = e.currentTarget.dataset;
    toggleKeywordFilter(name || '');
    setShowMenu(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      {title ? (
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 700,
            marginTop: {
              xs: '24px',
              md: '28px',
            },
            marginBottom: '16px',
          }}
        >
          {title}
        </Typography>
      ) : (
        ''
      )}
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <OutlinedInput
          onFocus={handleFocus}
          value={searchQuery}
          onInput={handleSearchFieldChange}
          type="search"
          placeholder={placeholder}
          endAdornment={
            <Icon icon="search" width={16} height={16} color={colors.steel} />
          }
          sx={{
            borderRadius: '8px',
            height: '48px',
            width: '100%',
            backgroundColor: colors.white,
            '& > fieldset': {
              borderColor: colors.steel,
            },
          }}
        />
      </Box>
      {showMenu ? (
        <>
          <Paper
            sx={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '100%',
              marginTop: '8px',
              zIndex: 4,
              padding: 0,
              background: colors.white,
              borderRadius: '8px',
              border: `1px solid ${colors.black500}`,
            }}
          >
            <MenuList>
              {!searchStarted ? (
                <Typography
                  variant="subtitle2"
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  Enter at least 3 symbols
                </Typography>
              ) : !keywordsResults.length ? (
                <Typography
                  variant="subtitle2"
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  No keywords found
                </Typography>
              ) : (
                keywordsResults.map(k => (
                  <MenuItem
                    data-name={k.name}
                    key={`keyword_${k.id}`}
                    onClick={onKeywordClick}
                  >
                    <Typography
                      variant="TagText"
                      sx={{
                        cursor: 'pointer',
                        whiteSpace: 'normal',
                        '& span': {
                          fontWeight: 700,
                        },
                      }}
                    >
                      # {getHighlightedText(k.name, searchQuery)}
                    </Typography>
                  </MenuItem>
                ))
              )}
            </MenuList>
          </Paper>
          <Box
            sx={{
              position: 'fixed',
              width: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            onClick={() => setShowMenu(false)}
          />
        </>
      ) : (
        ''
      )}
      {filterValues?.keywords.length || selectedKeywords.length ? (
        <KeywordValuesList
          keywords={filterValues?.keywords || selectedKeywords}
          onClearAll={clearAllKeywords}
          onRemoveKeyword={toggleKeywordFilter}
          hideResetFilter={hideResetFilter}
          sx={{
            paddingTop: 2,
          }}
        />
      ) : (
        ''
      )}
    </Box>
  );
}
