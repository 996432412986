import { MappedCategoryType } from '@/app/technologies/categories.types';
import { colors } from '@/lib/theme/palette';
import { OutlinedInput, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import Icon from '../Icon';
import ChipComponent from '@/shared/components/KeywordComponent';
import ButtonComponent from '../ButtonComponent';
import { TCategory } from '@/shared/apis/categories/types.categories';

type Filter = {
  name: string;
  value: string;
};

type Props = {
  categories: MappedCategoryType[];
  generalSubCategories: TCategory[];
  filterParams: string | null;
  selectedReactiveFilters?: string[];
  onRouteChange: (route: string) => void;
};

const CustomChip = ({
  filter,
  selectedChip,
  onClick,
}: {
  filter: Filter;
  selectedChip: boolean;
  onClick: () => void;
}) => {
  return (
    <ChipComponent
      onClick={onClick}
      key={`filter_chip_${filter.value.toLowerCase()}`}
      append={
        <Box component="div" display="flex" alignItems="center">
          <Icon
            icon={selectedChip ? 'close' : 'more'}
            width={17}
            height={17}
            color={colors.steel}
          />
        </Box>
      }
      sx={{
        padding: {
          xs: '6px 12px',
          sm: '6.42px 12px',
          xl: '10.1px 12px',
        },
        cursor: 'pointer',
        height: 'auto',

        ...(selectedChip && {
          backgroundColor: colors.black,
          border: `1px solid ${colors.black}`,
        }),
      }}
    >
      <Typography
        component="span"
        sx={{
          ...(selectedChip && {
            color: colors.white,
          }),
          fontSize: {
            xs: '12px',
            sm: '14px',
            xl: '16px',
          },
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          whiteSpace: 'pre-wrap',
        }}
      >
        {filter.name}
      </Typography>
    </ChipComponent>
  );
};

function TechonologiesGroupFilter({
  categories,
  generalSubCategories,
  filterParams,
  selectedReactiveFilters,
  onRouteChange,
}: Props) {
  const timerRef = useRef<number>();
  const [searchValue, setSearchValue] = useState<string>('');

  const [mappedCategories, setMappedCategories] = useState<
    MappedCategoryType[]
  >([]);

  const isChipSelected = (filter: Filter) => {
    return filterParams
      ? filterParams.split(',').includes(filter.value)
      : false;
  };
  const selectedFilters = generalSubCategories.filter(f => isChipSelected(f));

  const isSelected = (filter: Filter): boolean => {
    if (selectedReactiveFilters) {
      return selectedReactiveFilters.some(f => f === filter.value);
    }

    return selectedFilters.some(f => f.value === filter.value);
  };

  const handleSelectChip = (filter: Filter) => {
    if (selectedReactiveFilters) {
      if (selectedReactiveFilters.includes(filter.value)) {
        const newFilters = selectedReactiveFilters.filter(
          f => f !== filter.value,
        );

        const values = newFilters.join(',');

        onRouteChange(values);
        return;
      } else {
        const newFilters = [...selectedReactiveFilters, filter.value];

        const values = newFilters.join(',');

        onRouteChange(values);

        return;
      }
    }

    if (isChipSelected(filter)) {
      const newFilters = selectedFilters
        .filter(f => f.value !== filter.value)
        .map(f => f.value);

      const values = newFilters.join(',');

      onRouteChange(values);
    } else {
      const values = [...selectedFilters, filter].map(f => f.value).join(',');

      onRouteChange(values);
    }
  };

  const handleSearchCategories = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      const filteredCategories = categories.reduce(
        (acc: MappedCategoryType[], category: MappedCategoryType) => {
          const filteredSubcategories = category.subcategories
            ?.map(subcategory => (isSelected(subcategory) ? null : subcategory))
            .filter(subcategory =>
              subcategory?.name
                .toLowerCase()
                .includes(searchValue.toLowerCase()),
            );

          if (filteredSubcategories && filteredSubcategories.length > 0) {
            acc.push({
              ...category,
              subcategories: filteredSubcategories.filter(
                Boolean,
              ) as TCategory[],
            });
          }
          return acc;
        },
        [],
      );
      setMappedCategories([...filteredCategories]);
    }, 500);
  }, [searchValue]);

  return (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 700,
          marginTop: {
            xs: '24px',
            md: '28px',
          },
          marginBottom: '16px',
        }}
      >
        Technologies
      </Typography>

      <OutlinedInput
        value={searchValue}
        onInput={handleSearchCategories}
        placeholder={'Enter technology'}
        type="search"
        endAdornment={
          <Icon icon="search" width={16} height={16} color={colors.steel} />
        }
        sx={{
          borderRadius: '8px',
          height: '48px',
          width: '100%',
          backgroundColor: colors.white,
          '& > fieldset': {
            borderColor: colors.steel,
          },
        }}
      />

      {selectedReactiveFilters ? (
        selectedReactiveFilters.length ? (
          <Box
            marginTop={{
              xs: 2,
              md: 1,
            }}
            display={'flex'}
            flexWrap={'wrap'}
            columnGap={1}
            rowGap={1}
          >
            {selectedReactiveFilters.length > 1 ? (
              <ButtonComponent
                variant="danger"
                sx={{
                  border: `1px solid ${colors.red}`,
                  display: 'inline-flex',
                  width: 'auto',
                  height: {
                    xs: '34px',
                    sm: '34px',
                    xl: '44px',
                  },
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                    xl: '16px',
                  },

                  '&:hover': {
                    backgroundColor: colors.red,
                    color: colors.white,

                    '& svg': {
                      fill: colors.white,
                    },
                  },
                }}
                append={
                  <Icon
                    icon="close"
                    width={17}
                    height={17}
                    color={colors.red}
                  />
                }
                onClick={() => onRouteChange('')}
              >
                Reset all
              </ButtonComponent>
            ) : (
              <></>
            )}

            {selectedReactiveFilters
              ? selectedReactiveFilters.map(filter =>
                  CustomChip({
                    filter: generalSubCategories.find(
                      f => f.value === filter,
                    ) || {
                      name: filter,
                      value: filter,
                    },
                    selectedChip: true,
                    onClick: () =>
                      handleSelectChip(
                        generalSubCategories.find(f => f.value === filter) || {
                          name: filter,
                          value: filter,
                        },
                      ),
                  }),
                )
              : selectedFilters.map(filter =>
                  CustomChip({
                    filter: filter,
                    selectedChip: true,
                    onClick: () => handleSelectChip(filter),
                  }),
                )}
          </Box>
        ) : (
          <></>
        )
      ) : selectedFilters.length ? (
        <Box
          marginTop={{
            xs: 2,
            md: 1,
          }}
          display={'flex'}
          flexWrap={'wrap'}
          columnGap={1}
          rowGap={1}
          mb={3}
        >
          {selectedFilters.length > 1 ? (
            <ButtonComponent
              variant="danger"
              sx={{
                border: `1px solid ${colors.red}`,
                display: 'inline-flex',
                width: 'auto',
                height: {
                  xs: '34px',
                  sm: '34px',
                  xl: '44px',
                },
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                  xl: '16px',
                },

                '&:hover': {
                  backgroundColor: colors.red,
                  color: colors.white,

                  '& svg': {
                    fill: colors.white,
                  },
                },
              }}
              append={
                <Icon icon="close" width={17} height={17} color={colors.red} />
              }
              onClick={() => onRouteChange('')}
            >
              Reset all
            </ButtonComponent>
          ) : (
            <></>
          )}

          {selectedFilters.map(filter =>
            CustomChip({
              filter: filter,
              selectedChip: true,
              onClick: () => handleSelectChip(filter),
            }),
          )}
        </Box>
      ) : (
        <></>
      )}

      {mappedCategories.map(category => (
        <Box
          key={category.id}
          mb={2}
          marginTop={{
            xs: 2,
            md: 1,
          }}
        >
          <Typography variant="subtitle2" fontWeight={500} mb={1}>
            {category.name}
          </Typography>

          <Box
            paddingLeft={1}
            display={'flex'}
            flexWrap={'wrap'}
            columnGap={1}
            rowGap={1}
          >
            {category.subcategories
              ?.map(subcategory =>
                isSelected(subcategory) ? null : selectedFilters.length > 10 ? (
                  <>
                    <Tooltip title="A maximum of 10 categories can be selected at once">
                      <Typography
                        component="span"
                        sx={{
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                            xl: '16px',
                          },
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          whiteSpace: 'pre-wrap',

                          borderRadius: 2,
                          border: `1px solid ${colors.black300}`,

                          opacity: 0.5,

                          padding: {
                            xs: '6px 12px',
                            sm: '6.42px 12px',
                            xl: '10.1px 12px',
                          },
                          cursor: 'pointer',
                          height: 'auto',
                        }}
                      >
                        {subcategory.name}
                      </Typography>
                    </Tooltip>
                  </>
                ) : (
                  CustomChip({
                    filter: subcategory,
                    selectedChip: isSelected(subcategory),
                    onClick: () => handleSelectChip(subcategory),
                  })
                ),
              )
              .filter(Boolean)}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default TechonologiesGroupFilter;
