import React from 'react';
import { SxProps } from '@mui/system';
import { colors } from '@/lib/theme/palette';
import { Box, Chip, Typography } from '@mui/material';

type Props = {
  name?: string;
  append?: React.ReactNode;
  prepend?: React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
  sx?: SxProps;
  onClick?: () => void;
};

function Keyword({ name, append, prepend, children, sx, onClick }: Props) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Chip
      onClick={handleClick}
      label={
        <Typography
          component="span"
          variant="body1"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            color: colors.blue,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {prepend && (
            <Box component="span" marginRight={1.5}>
              {prepend}
            </Box>
          )}

          {children || `#${name}`}

          {append && (
            <Box
              component="span"
              display="flex"
              alignItems="center"
              marginLeft={1.5}
            >
              {append}
            </Box>
          )}
        </Typography>
      }
      color="info"
      sx={{
        overflow: 'hidden',
        padding: 2.5,
        borderRadius: 2,
        border: `1px solid ${colors.black300}`,
        '& .MuiChip-label': {
          padding: 0,
        },
        ...sx,
      }}
    />
  );
}

export default Keyword;
