'use client';
import React, { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { useSearchParams, useRouter } from 'next/navigation';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { colors } from '@/lib/theme/palette';
import theme from '@/lib/theme/theme';

type ItemsPaginationProps = {
  page?: number;
  catalogMode?: string;
  totalCount: number;
  callback?: (page: number) => void;
};

const Previous = () => {
  const isSmallScreen: boolean = useMediaQuery<boolean>(
    theme.breakpoints.down('sm'),
  );
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        border: `1px solid ${colors.blue10}`,
      }}
    >
      <ArrowBackIcon htmlColor={colors.blue} />
      {!isSmallScreen ? (
        <Typography
          variant="button"
          sx={{
            color: colors.blue,
            textTransform: 'capitalize',
          }}
        >
          Previous
        </Typography>
      ) : (
        ''
      )}
    </Box>
  );
};

const Next = () => {
  const isSmallScreen: boolean = useMediaQuery<boolean>(
    theme.breakpoints.down('sm'),
  );
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {!isSmallScreen ? (
        <Typography
          variant="button"
          sx={{
            color: colors.blue,
            textTransform: 'capitalize',
          }}
        >
          Next
        </Typography>
      ) : (
        ''
      )}
      <ArrowForwardIcon htmlColor={colors.blue} />
    </Box>
  );
};

export default function ItemsPagination({
  page,
  catalogMode,
  totalCount,
  callback,
}: ItemsPaginationProps) {
  // const isSmallScreen: boolean = useMediaQuery<boolean>(
  //   theme.breakpoints.down('sm'),
  // );
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const { push } = useRouter();
  const pageParam = params.get('page');
  const pageCountParam = params.get('pageCount');
  const pageCount = pageCountParam ? +pageCountParam : 12;
  const defaultPage = pageParam ? +pageParam : 1;
  const [currentPage, setCurrentPage] = React.useState(page || defaultPage);
  const countOfPages = Math.ceil(totalCount / pageCount);

  useEffect(() => {
    setCurrentPage(page || defaultPage);
  }, [page, defaultPage]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value || 1);
    if (callback) {
      callback(value);
    } else {
      params.set('page', value.toString());
      if (catalogMode) {
        push(`${catalogMode}?${params.toString()}`, { scroll: true });
      }
    }
  };

  return (
    <Box
      sx={{
        paddingTop: 4,
        marginTop: 4,
        borderTop: `1px solid ${colors.black400}`,
        position: {
          xs: 'relative',
          sm: 'initial',
        },
      }}
    >
      {/* {isSmallScreen ? (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="button"
            sx={{
              color: colors.grey700,
              textTransform: 'none',
            }}
          >
            Page {currentPage} of {countOfPages}
          </Typography>
        </Box>
      ) : (
        ''
      )} */}
      <Pagination
        count={countOfPages}
        shape="rounded"
        color="primary"
        page={currentPage}
        onChange={handleChange}
        sx={{
          '& .MuiPagination-ul': {
            justifyContent: 'center',
            position: 'relative',
            '& li:first-child': {
              position: 'absolute',
              left: 0,
            },
            '& li:last-child': {
              position: 'absolute',
              right: 0,
            },
          },
        }}
        renderItem={item => {
          return (
            <PaginationItem
              slots={{ previous: Previous, next: Next }}
              sx={{
                '&.MuiPaginationItem-previousNext': {
                  border: `1px solid ${colors.blue30}`,
                  backgroundColor: colors.blue10,
                  // padding: '10px 20px 10px 16px',
                },
              }}
              {...item}
            />
          );
          // return !isSmallScreen ||
          //   (isSmallScreen && ['previous', 'next'].includes(item.type)) ? (
          //   <PaginationItem
          //     slots={{ previous: Previous, next: Next }}
          //     sx={{
          //       '&.MuiPaginationItem-previousNext': {
          //         border: `1px solid ${colors.blue30}`,
          //         backgroundColor: colors.blue10,
          //         // padding: '10px 20px 10px 16px',
          //       },
          //     }}
          //     {...item}
          //   />
          // ) : (
          //   ''
          // );
        }}
      />
    </Box>
  );
}
