'use client';
import React from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FilterValues } from '@/shared/components/MobileFilter/types.d';
import { removeItemAll } from '@/lib/utils';
import { colors } from '@/lib/theme/palette';
import ChipComponent from '@/shared/components/KeywordComponent';
import Icon from '@/shared/components/Icon';
import { OutlinedInput, Tooltip } from '@mui/material';

type Filter = {
  name: string;
  value: string;
};

type ChipFilterProps = {
  title: string;
  filters: Filter[];
  search?: string;
  isMobile?: boolean;
  filterValues?: FilterValues;
  hasSearchField?: boolean;
  catalogMode?: string;
  searchPlaceholder?: string;
  filterName: 'companyType' | 'categories' | 'resourceType';
  callback?: (filter: string, value: string) => void;
  setFilterValues?: (filterValues: FilterValues) => void;
  onSearch?: (value: string) => void;
};

const maxMaximunSelected = 10;

const CustomChip = ({
  filter,
  selectedChip,
  onClick,
}: {
  filter: Filter;
  selectedChip: boolean;
  onClick: () => void;
}) => {
  return (
    <ChipComponent
      onClick={onClick}
      key={`filter_chip_${filter.value.toLowerCase()}`}
      append={
        <Box component="div" display="flex" alignItems="center">
          <Icon
            icon={selectedChip ? 'close' : 'more'}
            width={17}
            height={17}
            color={colors.steel}
          />
        </Box>
      }
      sx={{
        padding: {
          xs: '6px 12px',
          sm: '6.42px 12px',
          xl: '10.1px 12px',
        },
        cursor: 'pointer',
        height: 'auto',

        ...(selectedChip && {
          backgroundColor: colors.black,
          border: `1px solid ${colors.black}`,
        }),
      }}
    >
      <Typography
        component="span"
        sx={{
          ...(selectedChip && {
            color: colors.white,
          }),
          fontSize: {
            xs: '12px',
            sm: '14px',
            xl: '16px',
          },
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          whiteSpace: 'pre-wrap',
        }}
      >
        {filter.name}
      </Typography>
    </ChipComponent>
  );
};

export default function ChipFilter({
  title,
  filters,
  isMobile,
  filterName,
  catalogMode,
  filterValues,
  search,
  onSearch,
  searchPlaceholder = 'Search',
  setFilterValues,
}: ChipFilterProps) {
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const filterParams = params.get(filterName);
  const { push } = useRouter();
  const selectedLength = filterValues
    ? filterValues[filterName].length
    : filterParams
      ? filterParams.split(',').length
      : 0;

  // const allSelected = selectedLength === filters.length;

  const isChipSelected = (filter: Filter) => {
    return filterValues
      ? filterValues[filterName].includes(filter.value)
      : filterParams
        ? filterParams.split(',').includes(filter.value)
        : false;
  };
  const selectedFilters = filters.filter(f => isChipSelected(f));
  const nonSelectedFilters = filters.filter(f => !isChipSelected(f));

  const handleFilterChange = (valueName: string) => {
    if (filterValues && setFilterValues) {
      // filterValues && setFilterValues means it is mobile
      const currentValues = filterValues[filterName];
      let newValues: string[] = [];
      if (currentValues.includes(valueName)) {
        newValues = removeItemAll([...currentValues], valueName);
      } else {
        newValues = [...currentValues];
        newValues.push(valueName);
      }
      setFilterValues({
        ...filterValues,
        [filterName]: newValues,
      });
    } else {
      if (filterParams) {
        const existingFilters = filterParams.split(',');
        if (existingFilters.includes(valueName)) {
          const newValues = removeItemAll([...existingFilters], valueName);
          if (!newValues.length) {
            params.delete(filterName);
          } else {
            params.set(filterName, newValues.join(','));
          }
        } else {
          if (
            selectedFilters.length >= maxMaximunSelected &&
            filterName === 'categories'
          ) {
            return;
          }

          const newValues = [...existingFilters];
          newValues.push(valueName);
          params.set(filterName, newValues.join(','));
        }
      } else {
        params.set(filterName, valueName);
      }

      params.set('page', '1');
      if (catalogMode) {
        push(`${catalogMode}?${decodeURIComponent(params.toString())}`, {
          scroll: false,
        });
      }
    }
  };

  const renderChipList = (filters: Filter[], selectedChip?: boolean) => {
    return filters.map(filter => {
      if (
        selectedFilters.length >= maxMaximunSelected &&
        !selectedChip &&
        filterName === 'categories'
      ) {
        return (
          <>
            <Tooltip title="A maximum of 10 categories can be selected at once">
              <Typography
                component="span"
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                    xl: '16px',
                  },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  whiteSpace: 'pre-wrap',

                  borderRadius: 2,
                  border: `1px solid ${colors.black300}`,

                  opacity: 0.5,

                  padding: {
                    xs: '6px 12px',
                    sm: '6.42px 12px',
                    xl: '10.1px 12px',
                  },
                  cursor: 'pointer',
                  height: 'auto',
                }}
              >
                {filter.name}
              </Typography>
            </Tooltip>
          </>
        );
      }

      return CustomChip({
        filter,
        selectedChip: !!selectedChip,
        onClick: () => handleFilterChange(filter.value),
      });
    });
  };

  const handleClearAll = () => {
    if (filterValues && setFilterValues) {
      setFilterValues({
        ...filterValues,
        [filterName]: [],
      });
    } else {
      params.delete(filterName);
      params.set('page', '1');
      if (catalogMode) {
        push(`${catalogMode}?${decodeURIComponent(params.toString())}`, {
          scroll: false,
        });
      }
    }
  };

  const handleSearchTypes = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch && onSearch(e.target.value);
  };

  return (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 700,
          marginTop: {
            xs: '24px',
            md: '28px',
          },
          marginBottom: '16px',
          ...(isMobile
            ? {
                fontSize: '18px',
              }
            : {}),
        }}
      >
        {title}
      </Typography>
      <OutlinedInput
        value={search}
        onInput={handleSearchTypes}
        placeholder={searchPlaceholder}
        type="search"
        endAdornment={
          <Icon icon="search" width={16} height={16} color={colors.steel} />
        }
        sx={{
          borderRadius: '8px',
          height: '48px',
          width: '100%',
          backgroundColor: colors.white,
          '& > fieldset': {
            borderColor: colors.steel,
          },
        }}
      />
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        flexWrap="wrap"
        marginTop={{
          xs: 2,
          md: 1,
        }}
      >
        {selectedLength > 1 && (
          <ChipComponent
            onClick={handleClearAll}
            append={
              <Box
                component="div"
                display="flex"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Icon icon="close" width={17} height={17} color={colors.red} />
              </Box>
            }
            sx={{
              padding: '12px',
              cursor: 'pointer',
              height: {
                xs: '34px',
                sm: '34px',
                xl: '44px',
              },
              border: `1px solid ${colors.red}`,
              '&:hover': {
                backgroundColor: colors.red,

                '& span': {
                  color: colors.white,
                },

                '& svg': {
                  fill: colors.white,
                },
              },
            }}
          >
            <Typography
              component="span"
              color={colors.red}
              sx={{
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                  xl: '16px',
                },
              }}
            >
              Reset all
            </Typography>
          </ChipComponent>
        )}

        {renderChipList(selectedFilters, true)}
        {renderChipList(nonSelectedFilters)}
      </Box>
    </Box>
  );
}
