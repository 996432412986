import httpClient from '@/lib/client/httpClient';
import {
  TFetchResourceTypesParams,
  TFetchResourceTypes,
  TResourceType,
} from '@/shared/apis/resourceType/types.d';

const FetchResourceTypes: TFetchResourceTypes = async params => {
  return await httpClient.get<
    TFetchResourceTypesParams,
    { data: TResourceType[] }
  >('/resource-types', {
    params,
  });
};

const methods = {
  FetchResourceTypes,
};

export default methods;
