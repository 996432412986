'use client';
import React, { useState } from 'react';
import { useSearchParams } from 'next/navigation';
import Box from '@mui/material/Box';
import { colors } from '@/lib/theme/palette';
import Icon from '@/shared/components/Icon';
import ButtonComponent from '@/shared/components/ButtonComponent';
import FilterDialog from '@/shared/components/MobileFilter/FilterDialog';
import { MappedCategoryType } from '@/app/technologies/categories.types';
import { TCategory } from '@/shared/apis/categories/types.categories';
import QueryFilter from '../QueryFilter';

type DefaultFilterValues = {
  [key: string]: string[];
};

type MobileFilterProps = {
  searchType: string;
  catalogMode?: string | undefined;
  defaultFilterValues?: DefaultFilterValues;
  categories?: MappedCategoryType[];
  generalSubCategories?: TCategory[];
  hideSearch?: boolean;
  applyCallback?: (values: DefaultFilterValues) => void;
};

export default function MobileFilter({
  searchType,
  catalogMode,
  defaultFilterValues,
  applyCallback,
  categories,
  hideSearch,
  generalSubCategories,
}: MobileFilterProps) {
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const companyType = params.get('companyType');
  const categoriesType = params.get('categories');
  const resourceType = params.get('resourceType');
  const keywords = params.get('keywords');
  const query = params.get('query');
  const indicatorVisible =
    defaultFilterValues?.keywords.length ||
    defaultFilterValues?.resourceType.length ||
    (searchType === 'companies' && (companyType || categoriesType || query)) ||
    (searchType === 'resources' && (resourceType || keywords || query));
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      {!hideSearch ? (
        <QueryFilter
          hideTitle
          searchType={searchType}
          type="secondary"
          sx={{
            height: '36px',
            borderRadius: '4px',
            marginRight: '8px',
          }}
        />
      ) : (
        ''
      )}
      <ButtonComponent
        onClick={handleOpen}
        sx={{
          border: `1px solid ${colors.steel}`,
          backgroundColor: colors.white,
          width: '36px',
          height: '36px',
          flex: 0,
        }}
      >
        {indicatorVisible ? (
          <Box
            sx={{
              width: '4px',
              height: '4px',
              backgroundColor: colors.blue,
              position: 'absolute',
              borderRadius: '50%',
              top: '3px',
              right: '3px',
            }}
          />
        ) : (
          ''
        )}
        <Icon icon="filters" width={16} height={16} />
      </ButtonComponent>
      {open ? (
        <FilterDialog
          open={open}
          isMobile
          handleClose={handleClose}
          searchType={searchType}
          catalogMode={catalogMode}
          defaultFilterValues={defaultFilterValues}
          applyCallback={applyCallback}
          categories={categories || []}
          generalSubCategories={generalSubCategories || []}
        />
      ) : (
        ''
      )}
    </>
  );
}
