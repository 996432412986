import * as React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Typography } from '@mui/material';
import ChipComponent from '@/shared/components/KeywordComponent';
import { colors } from '@/lib/theme/palette';
import Icon from '@/shared/components/Icon';

type KeywordsListProps = {
  keywords: string[];
  onClearAll: () => void;
  onRemoveKeyword: (keyword: string) => void;
  sx?: SxProps;
  hideResetFilter?: boolean | undefined;
};

export default function KeywordValuesList({
  keywords,
  onClearAll,
  onRemoveKeyword,
  hideResetFilter,
  sx = {},
}: KeywordsListProps) {
  const keywordsElements: React.ReactElement[] = keywords.map(keyword => (
    <ChipComponent
      onClick={() => onRemoveKeyword && onRemoveKeyword(keyword)}
      key={`selected_keyword_${keyword}`}
      append={
        <Box component="div" display="flex" alignItems="center">
          <Icon icon="close" width={12} height={12} color={colors.steel} />
        </Box>
      }
      sx={{
        padding: {
          xs: '6px 12px',
          sm: '6.42px 12px',
          xl: '10.1px 12px',
        },
        cursor: 'pointer',
        height: 'auto',
      }}
    >
      <Typography
        component="span"
        sx={{
          fontSize: {
            xs: '12px',
            sm: '14px',
            xl: '16px',
          },
          color: colors.blue,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          whiteSpace: 'pre-wrap',
        }}
      >
        {keyword}
      </Typography>
    </ChipComponent>
  ));

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      columnGap={1}
      rowGap={1}
      sx={{ ...sx }}
    >
      {!hideResetFilter ? (
        <ChipComponent
          onClick={onClearAll}
          append={
            <Box
              component="div"
              display="flex"
              alignItems="center"
              sx={{
                cursor: 'pointer',
              }}
            >
              <Icon icon="close" width={17} height={17} color={colors.red} />
            </Box>
          }
          sx={{
            padding: '12px',
            height: {
              xs: '34px',
              sm: '34px',
              xl: '44px',
            },
            border: `1px solid ${colors.red}`,
            '&:hover': {
              backgroundColor: colors.red,

              '& span': {
                color: colors.white,
              },

              '& svg': {
                fill: colors.white,
              },
            },
          }}
        >
          <Typography
            component="span"
            color={colors.red}
            sx={{
              fontSize: {
                xs: '12px',
                sm: '14px',
                xl: '16px',
              },
            }}
          >
            Reset all
          </Typography>
        </ChipComponent>
      ) : (
        ''
      )}
      {keywordsElements}
    </Box>
  );
}
