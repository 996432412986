'use client';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type MobileComponentProps = {
  children: React.ReactNode;
};

export default function MobileRenderComponent({
  children,
}: MobileComponentProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return isMobileScreen ? <>{children}</> : '';
}
