import httpClient from '@/lib/client/httpClient';

import {
  FetchKeywordsList,
  TKeyword,
  TFetchKeywordsParams,
} from './types.keywords.d';

const GetKeywordsList: FetchKeywordsList = async (params, signal) => {
  return await httpClient.get<TFetchKeywordsParams, { data: TKeyword[] }>(
    '/keywords',
    {
      params,
      signal,
    },
  );
};

const methods = {
  GetKeywordsList,
};

export default methods;
